import {
  Center,
  Container,
  VStack,
  HStack,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Divider,
  Input,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Box,
  Spinner,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { Helmet } from 'react-helmet';
import React, { useEffect, useState, useRef } from 'react';
import { TextUnderline } from '../components/atoms';
import { Footer, Header } from '../components/molecules';
import axios from 'axios';
import $ from 'jquery';
var csv = require('jquery-csv');
import { useDebouncedCallback } from 'use-debounce';
import Loader from '../components/molecules/Loader';

const Search = () => {
  const searchRef = useRef(null);

  const [value, setValue] = useState('Airports');
  const [search, setSearch] = useState('');
  // const [searchDebounced] = useDebounce(search, 800);
  const [resources, setResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const [loading, setLoading] = useState(false);

  const tableHeads = [
    {
      name: 'Airports',
      key: 'airports',
      heads: ['Code', 'City', 'Airports', 'Country'],
    },
    {
      name: 'Seaports',
      key: 'seaports',
      heads: ['NO', 'PORT FULL NAME', 'NATION', 'PORT CODE'],
    },
    {
      name: 'Glossary',
      key: 'glossary',
      heads: ['Key', 'Value'],
    },
  ];

  let sheets = [
    {
      name: 'Seaports',
      sheet_id: '18Q3J3sc3HRZwHn3mOOytGasiTd4p7foHcdDz0ahzGw0',
    },
    {
      name: 'Airports',
      sheet_id: '141RJ3l7oMbyLBcPKJ7pv2eaKHpw2ARtr0WgdzMZres0',
    },
    {
      name: 'Glossary',
      sheet_id: '1fEiHIU_KFvbGlqvYbfTs0b7i995JoIlK',
    },
  ];

  const debounced = useDebouncedCallback((value) => {
    setSearch(value);
  }, 800);

  const filterResources = (resource: any) => {
    // @ts-ignore
    if (searchRef.current.value && searchRef.current.value.length > 0) {
      // @ts-ignore
      const allTableHeads = tableHeads.find((s) => s.name === value).heads;
      // @ts-ignore
      if (value === 'Glossary') {
        // @ts-ignore
        let filtered = resource.filter((item) =>
          allTableHeads.some((key) => {
            return (
              item[key]
                .toLowerCase()
                // @ts-ignore
                .includes(searchRef.current.value.toLowerCase())
            );
          }),
        );
        setFilteredResources(filtered);
      } else {
        // @ts-ignore
        let filtered = resource.filter((item) =>
          allTableHeads.some(
            (key) =>
              item[key] ===
              // @ts-ignore
              searchRef.current.value,
          ),
        );
        setFilteredResources(filtered);
      }
    } else {
      setFilteredResources(resource);
    }
  };

  const fetchCSV = (sheet_id: string) => {
    setLoading(true);
    axios
      .get(
        `https://docs.google.com/spreadsheets/export?id=${sheet_id}&exportFormat=csv`,
      )
      .then((res) => {
        let items = csv.toObjects(res.data);
        if (sheet_id === '1fEiHIU_KFvbGlqvYbfTs0b7i995JoIlK') {
          items = items.filter((item: any) => {
            return item['Key'].length !== 0;
          });
        }
        setResources(items);
        setLoading(false);
        filterResources(items);
      });
  };

  useEffect(() => {
    fetchCSV(sheets[1].sheet_id);
  }, []);

  useEffect(() => {
    filterResources(resources);
  }, [search, resources]);

  return (
    <>
      <Helmet>
        <title>Search | GFL Group of Companies</title>

        <link
          href="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
          rel="icon"
        ></link>
        <link
          href="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
          rel="apple-touch-icon"
        ></link>

        <meta
          content="width=device-width, initial-scale=1.0"
          name="viewport"
        ></meta>
        <meta
          content="logistics, suppliers, shipping, warehousing, malaysian, malaysia, klang, freight, air freight, ship freight, rail freight, cargo, cargo express"
          name="keywords"
        />
        <meta
          name="description"
          content="Your solution to total cargo transport"
        />
        <meta name="robots" content="index, follow"></meta>
        <link rel="canonical" href="https://www.gflgroup.com.my" />
        <meta
          property="og:title"
          content="Resources - GFL | Your solution to total cargo transport"
        ></meta>
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:description"
          content="Your solution to total cargo transport"
        ></meta>
        <meta property="og:url" content="https://www.gflgroup.com.my"></meta>
        <meta
          property="og:site_name"
          content="Resources - GFL | Your solution to total cargo transport"
        ></meta>
        <meta
          property="og:image"
          content="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
        ></meta>
        <meta property="twitter:card" content="summary large image"></meta>
        <meta
          property="twitter:label1"
          content="Your solution to total cargo transport"
        ></meta>
      </Helmet>
      <Loader /> <Header />
      <VStack spacing={5} alignItems={'flex-start'} w="100%">
        <HStack
          w="100%"
          h={['20vh', '50vh', '70vh']}
          backgroundAttachment={'unset'}
          backgroundRepeat="no-repeat"
          backgroundImage={'/resources/resources-banner.jpg'}
          backgroundSize={['100%', 'cover', 'cover']}
          backgroundPosition="0% center"
          position="sticky"
          justifyContent="center"
          pl={['1.5rem', '3rem', '6rem']}
        >
          <VStack w="100%" alignItems={'flex-start'} zIndex="4">
            <Heading
              fontSize={['1rem', '2.5rem', '3rem']}
              color="rgb(255, 255, 255)"
              className="title-animation"
            >
              GFL Search
            </Heading>
            <TextUnderline color="#ffffff" opacity=".8" />
          </VStack>
        </HStack>

        <Container maxW="container.xl" w="100%" mt="6em !important" mb="2rem">
          <VStack w="100%" alignItems={'center'}>
            <Heading as="h1" fontSize={'2.5rem'}>
              Search for more
            </Heading>
            <Text>Search for your Seaport, Airport Codes & Glossary</Text>

            <Divider
              style={{
                marginTop: '1em',
                marginBottom: '1em',
              }}
            />

            <HStack w="100%" flexDir={['column', 'column', 'row']}>
              <Box
                width={['100%', '100%', '75%']}
                mb={['1rem', '1rem', '0rem']}
              >
                <FormControl>
                  <FormLabel htmlFor="search">Search</FormLabel>
                  <Input
                    id="search"
                    type="text"
                    placeholder="Seaport, Airport Codes & Glossary"
                    ref={searchRef}
                    onChange={(e) => {
                      debounced(e.target.value);
                    }}
                  />
                </FormControl>
              </Box>

              <Box width={'100%'} maxW={['100%', '100%', '100%', '400px']}>
                <FormControl>
                  <FormLabel htmlFor="search">Types</FormLabel>
                  <Select
                    className="select"
                    // @ts-ignore
                    h="300px"
                    w="100% !important"
                    options={[
                      { value: 'Seaports', label: 'Seaports Codes' },
                      { value: 'Airports', label: 'Airports Codes' },

                      { value: 'Glossary', label: 'Glossary' },
                    ]}
                    // inputValue={value}

                    // @ts-ignore
                    onChange={(v: any) => {
                      setValue(v.value);

                      fetchCSV(
                        // @ts-ignore
                        sheets.find((s) => s.name === v.value).sheet_id,
                      );
                    }}
                  />
                </FormControl>
              </Box>
            </HStack>

            {loading ? (
              <Center w="100%" mt="50px !important">
                <Spinner size={'lg'} />
              </Center>
            ) : (
              <Box overflowX={'scroll'} w="100vw">
                <Table variant="simple" mt="50px !important">
                  <TableCaption>GFL Resources Table</TableCaption>
                  <Thead>
                    <Tr>
                      {value !== '' ? (
                        // @ts-ignore
                        tableHeads
                          .find((item) => item.name === value)
                          .heads.map((item, index) => (
                            <Th key={index}>{item}</Th>
                          ))
                      ) : (
                        <></>
                      )}
                    </Tr>
                  </Thead>

                  <Tbody>
                    {value === 'Airports' ? (
                      filteredResources.map((item: any, index) => (
                        <Tr key={index}>
                          <Td>{item.Code}</Td>
                          <Td>{item.City}</Td>
                          <Td>{item.Airport}</Td>
                          <Td>{item.Country}</Td>
                        </Tr>
                      ))
                    ) : value === 'Seaports' ? (
                      filteredResources.map((item: any, index) => (
                        <Tr key={index}>
                          <Td>{item.NO}</Td>
                          <Td>{item['PORT FULL NAME']}</Td>
                          <Td>{item['NATION']}</Td>
                          <Td>{item['PORT CODE']}</Td>
                        </Tr>
                      ))
                    ) : value === 'Glossary' ? (
                      filteredResources.map((item: any, index) => (
                        <Tr key={index}>
                          <Td>{item['Key']}</Td>
                          <Td>{item['Value']}</Td>
                        </Tr>
                      ))
                    ) : (
                      <></>
                    )}
                  </Tbody>
                </Table>
              </Box>
            )}
          </VStack>
        </Container>
      </VStack>
      <Footer />
    </>
  );
};

export default Search;
